import { makeAutoObservable, reaction } from 'mobx';

import ApiService from '../services/ApiService';
import ErrorUtil from '../utils/ErrorUtil';

import { ILanguageStore } from './LanguageStore';
import { ITranslation } from './models/ITranslations';

export interface ITranslateStore {
  init: () => void;

  isLoaded: boolean;
  failedTranslations: boolean;
  translations: Record<string, string>;

  translate(key: string): string;
}

class TranslateStore implements ITranslateStore {
  private allTranslations: ITranslation[] = [];
  public isLoaded = false;
  public failedTranslations = false;

  constructor(
    private languageStore: ILanguageStore,
    private apiService: ApiService
  ) {
    makeAutoObservable<TranslateStore, 'apiService' | 'languageStore'>(this, { apiService: false, languageStore: false }, { autoBind: true });
  }

  public init(): void {
    reaction(
      () => this.languageStore.currentLanguage,
      () => this.updateTranslations()
    );
    this.updateTranslations();
  }

  public translate = (key: string): string => {
    const translationObj = this.allTranslations.find((translation) => translation.key === key);
    return (
      (translationObj && translationObj[this.languageStore.currentLanguage]) ||
      (translationObj && translationObj[this.languageStore.defaultLanguage]) ||
      'Tõlge puudub'
    );
  };

  private markLoaded() {
    this.isLoaded = true;
  }

  private markFailed(failed: boolean) {
    this.failedTranslations = failed;
  }

  private setTranslations(translations: ITranslation[]) {
    this.allTranslations = translations || [];
  }

  get translations(): Record<string, string> {
    const translations = {} as Record<string, string>;
    this.allTranslations.forEach((translation) => {
      translations[translation.key] = translation[this.languageStore.currentLanguage] || translation[this.languageStore.defaultLanguage];
    });
    return translations;
  }

  private updateTranslations() {
    return this.apiService
      .getTranslations()
      .then((translations) => {
        this.setTranslations(translations.labels);
        this.markLoaded();
        this.markFailed(false);
      })
      .catch((error) => {
        ErrorUtil.registerError(error);
        this.markLoaded();
        this.markFailed(true);
      });
  }
}

export default TranslateStore;
