import { makeAutoObservable } from 'mobx';

import ApiService from '../services/ApiService';
import ErrorUtil from '../utils/ErrorUtil';

export default class ReferenceNumberStore {
  public number = '';
  public submitted = false;
  public referenceNumber = '';
  public responseError = false;

  constructor(private apiService: ApiService) {
    makeAutoObservable<ReferenceNumberStore, 'apiService'>(this, { apiService: false }, { autoBind: true });
  }

  public updateNumber(value: string): void {
    this.submitted = false;
    this.responseError = false;
    this.referenceNumber = '';
    this.number = value;
  }

  public getReferenceNumber = (): void => {
    this.setSubmitted(true);
    this.setResponseError(false);
    this.findReferenceNumber(this.number)
      .then((referenceNumber: string) => this.setReferenceNumber(referenceNumber))
      .catch((error) => {
        this.setResponseError(true);
        ErrorUtil.registerError(error);
      });
  };

  private setResponseError(isError: boolean) {
    this.responseError = isError;
  }

  private setSubmitted(isSubmitted: boolean) {
    this.submitted = isSubmitted;
  }

  private setReferenceNumber(number: string) {
    this.referenceNumber = number;
  }

  public async findReferenceNumber(phoneNumber: string): Promise<string> {
    return (await this.apiService.getReferenceNumber(phoneNumber)).referenceNumber;
  }
}
