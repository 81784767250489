const PERSONAL_CODE_PATTERN = '^[1-6][0-9][0-9][0-1][0-9][0-3][0-9][0-9][0-9][0-9][0-9]$';
const PERSONAL_CODE_WEIGHT_1 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 1];
const PERSONAL_CODE_WEIGHT_2 = [3, 4, 5, 6, 7, 8, 9, 1, 2, 3];

export const isStandardPersonalCode = (personalCode: string): boolean => {
  return !(personalCode === null || !personalCode.trim().match(PERSONAL_CODE_PATTERN)) && checkSumIsValid(personalCode.trim());
};

export const isInvalidPersonalCode = (personalCode: string): boolean => {
  return personalCode.length >= 11 && !isStandardPersonalCode(personalCode);
};

function checkSumIsValid(personalCode: string): boolean {
  const checksum: number = parseInt(personalCode.charAt(personalCode.length - 1), 10);
  let calculatedCheckSum: number = getCheckSum(personalCode, PERSONAL_CODE_WEIGHT_1) % 11;

  if (calculatedCheckSum !== 10) {
    return checksum === calculatedCheckSum;
  } else {
    calculatedCheckSum = getCheckSum(personalCode, PERSONAL_CODE_WEIGHT_2) % 11;
    if (calculatedCheckSum !== 10) {
      return checksum === calculatedCheckSum;
    }

    return checksum === 0;
  }
}

function getCheckSum(personalCode: string, weights: number[]) {
  let checkSum = 0;

  personalCode.split('').forEach((char: string, index: number) => {
    if (index !== personalCode.length - 1) {
      checkSum += parseInt(char.charAt(0), 10) * weights[index];
    }
  });

  return checkSum;
}
