import { makeAutoObservable } from 'mobx';

import ApiService from '../services/ApiService';

class EmailStore {
  public orderInvoice = false;
  public email = '';
  public validEmail = false;
  public validationDone = false;
  private apiService: ApiService;

  constructor(apiService: ApiService) {
    makeAutoObservable<EmailStore, 'apiService'>(this, { apiService: false }, { autoBind: true });
    this.apiService = apiService;
  }

  public updateOrderInvoice(value: boolean): void {
    this.orderInvoice = value;
  }

  public updateEmail(value: string): void {
    this.validationDone = false;
    this.email = value;
  }

  public async validateEmail(): Promise<boolean> {
    if (!this.orderInvoice) return true;

    if (this.email) {
      const emailPromise = await this.apiService.validateEmail(this.email);
      this.setValidEmail(emailPromise.validate === 'VALID');
    } else {
      this.setValidEmail(false);
    }

    this.setValidationDone(true);
    return this.validEmail;
  }

  private setValidEmail(isValid: boolean) {
    this.validEmail = isValid;
  }

  private setValidationDone(isDone: boolean) {
    this.validationDone = isDone;
  }

  get emailFieldInvalid(): boolean {
    return !this.validEmail && this.validationDone;
  }

  public emailFieldHelper(errorLabel: string): any {
    return this.emailFieldInvalid ? { text: errorLabel, type: 'error' } : null;
  }

  get orderEmail(): string {
    return this.orderInvoice && this.validEmail && this.validationDone ? this.email : null;
  }
}

export default EmailStore;
