import { RouterStore } from '@superwf/mobx-react-router';
import { createBrowserHistory } from 'history';
import { MobXProviderContext } from 'mobx-react';
import React from 'react';

import ApiService from '../services/ApiService';
import { RouteService } from '../services/RouteService';

import EmailStore from './EmailStore';
import LanguageStore, { ILanguageStore } from './LanguageStore';
import MobileNumberStore from './MobileNumberStore';
import PaymentMethodsStore from './PaymentMethodsStore';
import PopulationRegistryPersonStore from './PopulationRegistryPersonStore';
import PosPaymentStore from './PosPaymentStore';
import PrepaidDataStore from './PrepaidDataStore';
import PriceStore from './PriceStore';
import ReferenceNumberStore from './ReferenceNumberStore';
import RouteStore, { IRouteStore } from './RouteStore';
import SessionStore from './SessionStore';
import TranslateStore, { ITranslateStore } from './TranslateStore';

export default class RootStore {
  public apiService: ApiService;
  public routeService: RouteService;

  public routing: RouterStore;
  public routeStore: IRouteStore;

  public languageStore: ILanguageStore;
  public translateStore: ITranslateStore;
  public referenceNumberStore: ReferenceNumberStore;
  public mobileNumberStore: MobileNumberStore;
  public priceStore: PriceStore;
  public sessionStore: SessionStore;
  public populationRegistryPersonStore: PopulationRegistryPersonStore;
  public posPaymentStore: PosPaymentStore;
  public emailStore: EmailStore;
  public prepaidDataStore: PrepaidDataStore;
  public paymentMethodsStore: PaymentMethodsStore;

  constructor() {
    this.apiService = new ApiService();

    this.routing = new RouterStore(createBrowserHistory());
    this.routeStore = new RouteStore(this.routing);
    this.routeService = new RouteService(this.routeStore);

    this.languageStore = new LanguageStore(this.routeStore);

    this.translateStore = new TranslateStore(this.languageStore, this.apiService);
    this.translateStore.init();
    this.referenceNumberStore = new ReferenceNumberStore(this.apiService);
    this.sessionStore = new SessionStore(this.apiService);
    this.populationRegistryPersonStore = new PopulationRegistryPersonStore(this.apiService);
    this.priceStore = new PriceStore(this.apiService);
    this.emailStore = new EmailStore(this.apiService);
    this.mobileNumberStore = new MobileNumberStore(this.routeStore, this.apiService);
    this.prepaidDataStore = new PrepaidDataStore(
      this.mobileNumberStore,
      this.priceStore,
      this.emailStore,
      this.sessionStore,
      this.referenceNumberStore,
      this.populationRegistryPersonStore
    );
    this.posPaymentStore = new PosPaymentStore(this.apiService, this.routeStore, this.priceStore, this.prepaidDataStore, this.populationRegistryPersonStore);
    this.paymentMethodsStore = new PaymentMethodsStore(
      this.routeStore,
      this.priceStore,
      this.emailStore,
      this.routeService,
      this.languageStore,
      this.prepaidDataStore
    );
  }
}

export function useStores(): RootStore {
  return React.useContext(MobXProviderContext) as RootStore;
}
