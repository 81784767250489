import { observer } from 'mobx-react';
import * as React from 'react';
import { Button, Grid, GridColumn, Notice, TextField } from 'telia-front-react';

export interface IReferenceNumberFormProps {
  value: string;
  onChange: (string) => void;
  isInvalid: boolean;
  invalidText: string;
  buttonText: string;
  onSubmit: (string) => void;
  result: string;
}

const ReferenceNumberForm = observer((props: IReferenceNumberFormProps) => {
  return (
    <div className="text">
      <Grid>
        <GridColumn width={['lg-4', 'md-5', 'sm-7']}>
          <TextField
            id="referencePhone"
            label="+372"
            focusLabel="+372"
            type="tel"
            value={props.value}
            onChange={props.onChange}
            isInvalid={props.isInvalid}
            helper={props.isInvalid ? { text: props.invalidText, type: 'error' } : null}
            onKeyDown={(event) => {
              if (event.key === 'Enter') props.onSubmit(props.value);
            }}
          />
        </GridColumn>
        <GridColumn width={['sm']}>
          <Button type="primary" text={props.buttonText} onClick={props.onSubmit} />
        </GridColumn>
      </Grid>
      {props.result && <Notice type="success">{props.result}</Notice>}
    </div>
  );
});

export default ReferenceNumberForm;
