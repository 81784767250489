const StatusUtil = {
  getStatus(status: string): PaymentStatus {
    if (!status) {
      return null;
    } else if (status.toLowerCase() === 'success') {
      return 'success';
    } else if (status.toLowerCase() === 'cancelled') {
      return 'cancelled';
    } else if (status.toLowerCase() === 'processing') {
      return 'processing';
    }
    return null;
  }
};

export default StatusUtil;
