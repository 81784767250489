export enum PrepaidBrand {
  POP = 'POP',
  DIIL = 'DIIL',
  SUPER = 'SUPER',
  PRION = 'PRION',
  WALK4GIG = 'WALK4GIG',
  NETIKAART = 'NETIKAART',
  UNKNOWN = 'UNKNOWN'
}
export interface IPrepaidInfo {
  msisdn: string;
  balance: number;
  exists: boolean;
  prepaid: boolean;
  payable: boolean;
  serviceClass: string;
  brand: PrepaidBrand;
  days: string;
  expiryDate: string;
  usedOfferId: string;
}
