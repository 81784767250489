import * as React from 'react';

const ErrorUtil = {
  registerError(error: Error): void {
    this.logError(error, null);
    this.sendErrorToPlumbr(error);
  },

  registerReactError(error: Error, errorInfo: React.ErrorInfo): void {
    this.logError(error, errorInfo);
    this.sendErrorToPlumbr(error);
  },

  logError(error: Error, errorInfo: React.ErrorInfo): void {
    if (errorInfo == null) {
      console.error('Unexpected error', error);
    } else {
      console.error(error, errorInfo);
    }
  },

  sendErrorToPlumbr(error: Error): void {
    const plumbr = (window as any).PLUMBR;
    if (plumbr != null) {
      plumbr.sendError(error);
    }
  }
};

export default ErrorUtil;
