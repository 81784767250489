import { observer } from 'mobx-react';
import * as React from 'react';
import { Heading, HorizontalProgress, HorizontalProgressItem, Selector, Separator, Spinner } from 'telia-front-react';

import { IPrice } from '../stores/models/IPrices';
import { useStores } from '../stores/RootStore';

import PaymentMethods from './PaymentMethods';
import PrepaidDataComponent from './PrepaidDataComponent';

const PrePaymentComponent = observer(() => {
  const { translateStore, prepaidDataStore, priceStore } = useStores();
  const translate = translateStore.translate;

  const getPriceSelector = (): JSX.Element => {
    return (
      <>
        {prepaidDataStore.loading ? (
          <Spinner />
        ) : (
          priceStore.priceItems && (
            <Selector
              id="price-selector"
              size="xlarge"
              label={translate('prepaid.step.price.title')}
              hideLabel={true}
              items={getSelectorItems()}
              value={(priceStore.selectedPrice && priceStore.selectedPrice.code) || null}
              onChange={priceStore.updatePrice}
              itemWidth={['xs-3']}
            />
          )
        )}
      </>
    );
  };

  const getSelectorItems = (): any[] => {
    return priceStore.priceItems.map((price: IPrice) => {
      return {
        id: `${price.code}`,
        label: `${parseInt(price.price)} €`,
        value: `${price.code}`,
        name: `${price.name}`,
        disabled: stepIndex() == 0
      };
    });
  };

  const getStepState = (index: number): 'done' | 'current' | 'disabled' => {
    if (index < stepIndex()) return 'done';
    else if (index === stepIndex()) return 'current';
    else return 'disabled';
  };

  const stepIndex = (): number => {
    if (priceStore.isStepDone) return 2;
    else if (prepaidDataStore.isStepDone) return 1;
    else return 0;
  };

  return (
    <>
      <Heading element="h3">{translate('prepaid.title')}</Heading>
      <Separator spacing="xxsmall" color="invisible" />
      <HorizontalProgress break="md">
        <HorizontalProgressItem title={translate('prepaid.step.number.title')} state={getStepState(0)}>
          <PrepaidDataComponent disabled={stepIndex() !== 0} />
        </HorizontalProgressItem>
        <HorizontalProgressItem title={translate('prepaid.step.price.title')} state={getStepState(1)}>
          {getPriceSelector()}
        </HorizontalProgressItem>
        <HorizontalProgressItem title={translate('prepaid.step.bank.title')} state={getStepState(2)}>
          <PaymentMethods disabled={stepIndex() !== 2} />
        </HorizontalProgressItem>
      </HorizontalProgress>
      <Separator color="invisible" spacing="xxsmall" />
    </>
  );
});

export default PrePaymentComponent;
