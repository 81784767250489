import { RouterStore } from '@superwf/mobx-react-router';
import type { Location } from 'history';
import { makeAutoObservable } from 'mobx';

export interface IRouteStore {
  readonly pathName: string;
  readonly location: Location;
  readonly pageLanguageUrlPath: string;
  readonly queryParams: URLSearchParams;
  path: string[];

  queryParam(name: string): string;

  push(url: string): void;

  replace(url: string): void;

  goToExternalUrl(href: string): void;
}

class RouteStore implements IRouteStore {
  constructor(private routing: RouterStore) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  get pathName(): string {
    return this.routing.location.pathname;
  }

  get location(): Location {
    return this.routing.location;
  }

  get pageLanguageUrlPath(): string {
    if (this.pathIncludesLanguage()) {
      return '/' + this.getLanguageFromPath();
    }
    return '';
  }

  public queryParam(name: string): string {
    return new URLSearchParams(this.location.search).get(name);
  }

  get queryParams(): URLSearchParams {
    return new URLSearchParams(this.location.search);
  }

  public push(url: string): void {
    this.routing.push(url);
  }

  public replace(url: string): void {
    this.routing.replace(url);
  }

  public goToExternalUrl(href: string): void {
    window.location.href = href;
  }

  get path(): string[] {
    return this.pathName.split('/').filter((path) => path !== '');
  }

  private pathIncludesLanguage(path: string[] = this.path): boolean {
    return path && path.length > 0 && ['et', 'ru', 'en'].includes(path[0]);
  }

  private getLanguageFromPath(): Language {
    return this.pathIncludesLanguage() ? (this.path[0] as Language) : null;
  }
}

export default RouteStore;
