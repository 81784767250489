import { observer } from 'mobx-react';
import * as React from 'react';
import { Check, Separator, TextField } from 'telia-front-react';

import { useStores } from '../stores/RootStore';

interface IEmailComponentProps {
  disabled: boolean;
}

const EmailComponent = observer((props: IEmailComponentProps) => {
  const { translateStore, emailStore } = useStores();
  const translate = translateStore.translate;
  return (
    <>
      <Separator color="invisible" spacing="xsmall" />
      <Check
        id="order-invoice-check"
        value="order-invoice-check"
        labelText={translate('prepaid.step.number.email-check')}
        onChange={emailStore.updateOrderInvoice}
        disabled={props.disabled}
      />

      {emailStore.orderInvoice && (
        <>
          <Separator color="invisible" spacing="xxsmall" />
          <TextField
            id="order-invoice-email-textfield"
            label={translate('prepaid.step.number.email-label')}
            value={emailStore.email}
            onChange={emailStore.updateEmail}
            helper={emailStore.emailFieldHelper(translate('prepaid.validation.email-error'))}
            isInvalid={emailStore.emailFieldInvalid}
            isDisabled={props.disabled}
          />
        </>
      )}
      <Separator color="invisible" spacing="xxsmall" />
    </>
  );
});
export default EmailComponent;
