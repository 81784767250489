import { makeAutoObservable } from 'mobx';

import ApiService from '../services/ApiService';
import ErrorUtil from '../utils/ErrorUtil';
import { isStandardPersonalCode } from '../utils/PersonalCodeUtil';

class PopulationRegistryPersonStore {
  public personalCode = '';
  public personName = '';
  public isValidPersonalCode = false;
  public personNameHasError = false;

  constructor(private apiService: ApiService) {
    makeAutoObservable<PopulationRegistryPersonStore, 'apiService'>(this, { apiService: false }, { autoBind: true });
  }

  private setPersonName(personName: string) {
    this.personName = personName;
  }

  public getPopulationRegistryPerson = (): void => {
    this.apiService
      .getPopulationRegistryPerson(this.personalCode)
      .then((person) => {
        this.setPersonName(person.firstName + ' ' + person.lastName);
      })
      .catch((error) => {
        this.setPeronNameHasError(true);
        ErrorUtil.registerError(error);
      });
  };

  public updatePopulationRegistryPerson(value: string): void {
    this.personalCode = value;
    this.isValidPersonalCode = isStandardPersonalCode(this.personalCode);

    if (this.isValidPersonalCode) {
      this.getPopulationRegistryPerson();
    }
  }

  public personalCodeFieldHelper(errorLabel: string): any {
    return !this.isValidPersonalCode && this.personalCode !== '' ? { text: errorLabel, type: 'error' } : null;
  }

  public personNameFieldHelper(errorLabel: string, infoLabel: string): any {
    return this.personNameHasError ? { text: errorLabel, type: 'error' } : !this.personName ? { text: infoLabel, type: 'help' } : null;
  }

  private setPeronNameHasError(hasError: boolean) {
    this.personNameHasError = hasError;
  }
}

export default PopulationRegistryPersonStore;
