import { QueryParam } from '../Constants';
import { IRouteStore } from '../stores/RouteStore';

export class RouteService {
  constructor(private routeStore: IRouteStore) {}

  public createUrl(pathWithoutLanguage: string): string {
    return this.routeStore.pageLanguageUrlPath + pathWithoutLanguage;
  }

  public createAbsoluteUrlWithCurrentParams(pathWithoutLanguageAndSegment: string): string {
    let paramsString = this.routeStore.queryParams.toString();
    if (paramsString != '') paramsString = '?' + paramsString;
    return this.createAbsoluteUrl(pathWithoutLanguageAndSegment + paramsString);
  }

  public createAbsoluteUrl(pathWithoutLanguageAndSegment: string): string {
    return window.location.origin + this.createUrl(pathWithoutLanguageAndSegment);
  }

  public goToStatusUrl(status: PaymentStatus) {
    const params = this.routeStore.queryParams;
    params.delete(QueryParam.CardTransactionReference);
    params.set(QueryParam.Status, status);
    const redirectTo = this.createUrl('?' + params.toString());
    this.routeStore.push(redirectTo);
  }
}
