const BrandUtil = {
  getBrandType(brand: string): Brand {
    if (!brand) {
      return 'TELIA';
    }
    if (brand.toUpperCase() === 'SIMPEL') {
      return 'SIMPEL';
    } else if (brand.toUpperCase() === 'DIIL') {
      return 'DIIL';
    } else if (brand.toUpperCase() === 'SUPER') {
      return 'SUPER';
    }

    return 'TELIA';
  }
};

export default BrandUtil;
