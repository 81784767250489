import { makeAutoObservable } from 'mobx';

import ApiService from '../services/ApiService';
import ErrorUtil from '../utils/ErrorUtil';
import { formatPrice } from '../utils/NumberUtil';

import { PosPaymentRequest } from './models/PosPaymentRequest';
import PopulationRegistryPersonStore from './PopulationRegistryPersonStore';
import PrepaidDataStore from './PrepaidDataStore';
import PriceStore from './PriceStore';
import { IRouteStore } from './RouteStore';

class PosPaymentStore {
  public failedPosRequest = false;

  constructor(
    private apiService: ApiService,
    private routeStore: IRouteStore,
    private priceStore: PriceStore,
    private prepaidDataStore: PrepaidDataStore,
    private populationRegistryPersonStore: PopulationRegistryPersonStore
  ) {
    makeAutoObservable<PosPaymentStore, 'apiService'>(this, { apiService: false }, { autoBind: true });
  }

  public createPayment(): void {
    this.setFailedPostRequest(false);
    const paymentRequest: PosPaymentRequest = {
      amount: formatPrice(this.priceStore.selectedPrice.price),
      msisdn: this.prepaidDataStore.mobileNumber,
      description: this.populationRegistryPersonStore.personName
    };
    this.apiService
      .postPosPayment(paymentRequest)
      .then((posPayment) => this.routeStore.goToExternalUrl(posPayment.paymentUrl))
      .catch((error) => {
        ErrorUtil.registerError(error);
        this.setFailedPostRequest(true);
      });
  }

  private setFailedPostRequest(failed: boolean) {
    this.failedPosRequest = failed;
  }
}

export default PosPaymentStore;
