import { makeAutoObservable } from 'mobx';

import ApiService from '../services/ApiService';
import ErrorUtil from '../utils/ErrorUtil';
import { formatPrice } from '../utils/NumberUtil';

import { IPrice } from './models/IPrices';

class PriceStore {
  public selectedPrice: IPrice = null;
  public priceItems: IPrice[] = [];
  public noPlans = false;

  constructor(private apiService: ApiService) {
    makeAutoObservable<PriceStore, 'apiService'>(this, { apiService: false }, { autoBind: true });
  }

  public async tryLoadPrices(phoneNumber: string): Promise<void> {
    return this.apiService
      .getPrices(phoneNumber)
      .then((prices) => {
        if (prices && prices.priceplans && prices.priceplans.length) {
          this.updatePriceItems(
            prices.priceplans.map((plan) => {
              plan.price = formatPrice(plan.price);
              return plan;
            })
          );
        }
        this.setNoPlans(this.priceItems.length == 0);
      })
      .catch((error) => {
        ErrorUtil.registerError(error);
        this.setNoPlans(true);
      });
  }

  public updatePrice(code: string | string[]): void {
    this.selectedPrice = this.priceItems.find((price) => price.code === code);
  }

  private setNoPlans(hasNoPlans: boolean): void {
    this.noPlans = hasNoPlans;
  }

  private updatePriceItems(priceItems: IPrice[]): void {
    this.priceItems = priceItems;
  }

  get isStepDone(): boolean {
    return this.selectedPrice != null;
  }
}

export default PriceStore;
