import { enGB, et, ru } from 'date-fns/locale';
import { makeAutoObservable, reaction } from 'mobx';
import { pathToRegexp } from 'path-to-regexp';

import CookieUtil from '../utils/CookieUtil';

import { IRouteStore } from './RouteStore';

const LANGUAGE_UPPERCASE_PREFIXES = {
  et: 'EST',
  ru: 'RUS',
  enGB: 'ENG'
};

export interface ILanguageStore {
  readonly currentLanguage: Language;
  readonly currentLocale: Locale;
  readonly upperCaseThreeCharLangPrefix: string;
  readonly defaultLanguage: Language;

  getCurrentUrlWithLanguage(language: Language): string;
}

class LanguageStore implements ILanguageStore {
  public defaultLanguage: Language = 'et';
  private languages: Language[] = ['et', 'ru', 'en'];
  private languageCookieName = 'LANGUAGE_EXT';
  private pathLanguagePrefixPattern = `/:lang(${this.languages.join('|')})?`;
  private pathLanguageRegexp = pathToRegexp(this.pathLanguagePrefixPattern + '(.*)');
  private languageIdToLanguageMap = {
    et_EE: 'et',
    ru_RU: 'ru',
    en_GB: 'en'
  };

  private languageToLocaleMap = {
    et: et,
    ru: ru,
    en: enGB
  };

  private routeStore: IRouteStore;

  constructor(routeStore: IRouteStore) {
    makeAutoObservable<LanguageStore, 'routeStore'>(this, { routeStore: false }, { autoBind: true });
    this.routeStore = routeStore;
    const languageFromPath: Language = this.getLanguageFromPath();
    if (this.languages.includes(languageFromPath)) {
      this.updateLanguageCookie(languageFromPath);
    } else {
      if (!this.languages.includes(this.cookieLanguage)) {
        this.updateLanguageCookie(this.defaultLanguage);
      }
      this.changeLocationBasedOnLanguageCookie();
    }

    reaction(
      () => this.currentLanguage,
      (language) => {
        this.updateLanguageCookie(language);
      }
    );
  }

  get currentLanguage(): Language {
    return this.getLanguageFromPath() || this.defaultLanguage;
  }

  get upperCaseThreeCharLangPrefix(): string {
    return LANGUAGE_UPPERCASE_PREFIXES[this.currentLanguage];
  }

  public getCurrentUrlWithLanguage(language: Language): string {
    return this.getUrlWithLanguage(this.routeStore.location.pathname, this.routeStore.location.search, language);
  }

  get currentLocale(): Locale {
    return this.languageToLocaleMap[this.currentLanguage];
  }

  private getLanguageFromPath(): Language {
    return this.languages.find((language) => this.routeStore.path.length > 0 && language == this.routeStore.path[0]);
  }

  private getUrlWithLanguage(path: string, search: string, language: Language): string {
    if (!path.startsWith('/')) {
      path = '/' + path;
    }

    const prefix: string = language === this.defaultLanguage ? '' : `/${language}`;
    return prefix + this.pathLanguageRegexp.exec(path)[2] + search;
  }

  private changeLocationBasedOnLanguageCookie(): void {
    const languageInCookie: Language = this.cookieLanguage;

    if (languageInCookie && languageInCookie !== this.getLanguageFromPath()) {
      this.routeStore.replace(this.getCurrentUrlWithLanguage(languageInCookie));
    }
  }

  private updateLanguageCookie(language: Language) {
    if (CookieUtil.getCookieValue(this.languageCookieName) !== language) {
      const languageId = this.getLanguageIdFromLanguage(language);
      const expirationDate = new Date();
      expirationDate.setFullYear(new Date().getFullYear() + 1);
      CookieUtil.setCookie(this.languageCookieName, languageId, expirationDate.toUTCString());
    }
  }

  private getLanguageIdFromLanguage(language: Language): string {
    return Object.keys(this.languageIdToLanguageMap).find((key) => this.languageIdToLanguageMap[key] === language);
  }

  private getLanguageFromLanguageId(languageId: string): Language {
    return this.languageIdToLanguageMap[languageId];
  }

  private get cookieLanguage(): Language {
    const languageId = CookieUtil.getCookieValue(this.languageCookieName);
    return this.getLanguageFromLanguageId(languageId);
  }
}

export default LanguageStore;
