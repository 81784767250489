import { makeAutoObservable } from 'mobx';

import ApiService from '../services/ApiService';

import { ISession } from './models/ISession';

class SessionStore {
  private session: ISession = new ISession();

  constructor(private apiService: ApiService) {
    makeAutoObservable<SessionStore, 'apiService'>(this, { apiService: false }, { autoBind: true });
  }

  public loadSession(): void {
    this.apiService.getSession().then((session) => this.updateSession(session));
  }

  public updateSession(session: ISession): void {
    this.session = session;
  }

  get isDealer(): boolean {
    return this.session.dealer;
  }
}

export default SessionStore;
