import { observer, Provider } from 'mobx-react';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Container, LabelProvider, Main, MainContent, Notice, Spinner, ThemeProvider } from 'telia-front-react';

import { QueryParam } from './Constants';
import PrePaidView from './pages/PrePaidView';
import RootStore from './stores/RootStore';
import BrandUtil from './utils/BrandUtil';
import ErrorUtil from './utils/ErrorUtil';
import StatusUtil from './utils/StatusUtil';
import('./css/styles.css');

interface IAppState {
  hasError: boolean;
  brand: Brand;
}

export const getIconPath = (name: string): string => {
  const req = require.context('@telia-front/icon/lib/svg/', false, /^\.\/icons.svg$/);
  return req(req.keys()[0]) + name;
};

@observer
class App extends React.Component<unknown, IAppState> {
  private readonly rootStore: RootStore;

  constructor(props: unknown) {
    super(props);

    this.rootStore = new RootStore();
    this.state = {
      hasError: false,
      brand: (this.rootStore.routeStore.queryParam(QueryParam.Brand) as Brand) || 'TELIA'
    };
  }

  public componentDidMount(): void {
    this.rootStore.sessionStore.loadSession();
  }

  public componentDidCatch(error: Error): void {
    this.setState({ hasError: true });
    ErrorUtil.registerError(error);
  }

  public render(): JSX.Element {
    const brand = BrandUtil.getBrandType(this.state.brand).toLowerCase();
    return (
      <Provider {...this.rootStore}>
        <ThemeProvider
          getIconPath={getIconPath}
          locale={this.rootStore.languageStore.currentLocale}
          // @ts-ignore
          brand={brand}
          theme={brand === 'super' ? 'dark' : undefined}
        >
          <LabelProvider labels={this.rootStore.translateStore.translations}>
            <Main>{this.mainContent()}</Main>
          </LabelProvider>
        </ThemeProvider>
      </Provider>
    );
  }

  private mainContent(): JSX.Element {
    const langPaths = ['', 'et', 'en', 'ru'];
    const status = StatusUtil.getStatus(this.rootStore.routeStore.queryParam(QueryParam.Status));

    if (!this.rootStore.translateStore.isLoaded) return <Spinner />;

    if (this.state.hasError) {
      return (
        <MainContent>
          <Container>
            <Notice type="error">Vabandust, tekkis tehniline viga</Notice>
          </Container>
        </MainContent>
      );
    }

    return (
      <MainContent>
        <Container>
          <Helmet title={this.rootStore.translateStore.translate('site.title')} htmlAttributes={{ lang: this.rootStore.languageStore.currentLanguage }} />
          <BrowserRouter>
            <Routes>
              {langPaths.map((basePath, i) => (
                <React.Fragment key={`${basePath}${i}`}>
                  <Route path={basePath} element={<PrePaidView status={status} />} />
                  <Route path={`${basePath}/payment-success`} element={<PrePaidView status={'success'} />} />
                  <Route path={`${basePath}/payment-cancelled`} element={<PrePaidView status={'cancelled'} />} />
                  <Route path={`${basePath}/payment-processing`} element={<PrePaidView status={'processing'} />} />
                </React.Fragment>
              ))}
            </Routes>
          </BrowserRouter>
        </Container>
      </MainContent>
    );
  }
}

export default App;
