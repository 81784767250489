import { makeAutoObservable } from 'mobx';

import EmailStore from './EmailStore';
import MobileNumberStore from './MobileNumberStore';
import { PrepaidBrand } from './models/IPrepaidInfo';
import PopulationRegistryPersonStore from './PopulationRegistryPersonStore';
import PriceStore from './PriceStore';
import ReferenceNumberStore from './ReferenceNumberStore';
import SessionStore from './SessionStore';

class PrepaidDataStore {
  public loading = false;
  public referenceNumber: string;
  public email: string;
  public mobileNumber: string;
  public brand: PrepaidBrand;

  constructor(
    private mobileNumberStore: MobileNumberStore,
    private priceStore: PriceStore,
    private emailStore: EmailStore,
    private sessionStore: SessionStore,
    private referenceNumberStore: ReferenceNumberStore,
    private populationRegistryPersonStore: PopulationRegistryPersonStore
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public submitForm = (): void => {
    this.validateDataAndGoToNextStep();
  };

  private async validateDataAndGoToNextStep(): Promise<any> {
    this.setLoading(true);

    if (await this.emailStore.validateEmail()) this.setEmail(this.emailStore.orderEmail);
    if (await this.mobileNumberStore.validateMobileNumber()) {
      this.setMobileNumber(this.mobileNumberStore.mobileNumberField);
      this.setBrand(this.mobileNumberStore.prepaidInfo.brand);
    }

    if (this.brand) {
      await this.priceStore.tryLoadPrices(this.mobileNumber);
      const referenceNumber = await this.referenceNumberStore.findReferenceNumber(this.mobileNumber);
      this.setReferenceNumber(referenceNumber);
    }

    this.setLoading(false);
  }

  private setLoading(isLoading: boolean) {
    this.loading = isLoading;
  }

  private setMobileNumber(number: string) {
    this.mobileNumber = number;
  }

  private setBrand(brand: PrepaidBrand) {
    this.brand = brand;
  }

  private setReferenceNumber(number: string) {
    this.referenceNumber = number;
  }

  private setEmail(email: string) {
    this.email = email;
  }

  get showEmailComponent(): boolean {
    return !this.sessionStore.isDealer;
  }

  get isStepDone(): boolean {
    if (!this.mobileNumberStore.validMobileNumber || this.priceStore.priceItems.length == 0) return false;
    if (this.emailStore.orderInvoice && !this.email) return false;

    if (this.sessionStore.isDealer && this.mobileNumberStore.detaineeNumber) {
      return this.populationRegistryPersonStore.isValidPersonalCode && this.populationRegistryPersonStore.personName.length > 0;
    }

    return true;
  }
}

export default PrepaidDataStore;
