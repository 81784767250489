import { makeAutoObservable } from 'mobx';

import ApiService from '../services/ApiService';
import ErrorUtil from '../utils/ErrorUtil';

import { IPrepaidInfo, PrepaidBrand } from './models/IPrepaidInfo';
import { IRouteStore } from './RouteStore';

class MobileNumberStore {
  public mobileNumberField = '';
  public prepaidInfo: IPrepaidInfo = null;
  public validNumber = true;

  constructor(
    private routeStore: IRouteStore,
    private apiService: ApiService
  ) {
    makeAutoObservable<MobileNumberStore, 'routeStore' | 'apiService'>(this, { routeStore: false, apiService: false }, { autoBind: true });
  }

  public init(): void {
    this.handleQueryParamMsisdn();
  }

  public setMobileNumber(mobileNumber: string): void {
    this.mobileNumberField = mobileNumber.trim();
  }

  public async validateMobileNumber(): Promise<boolean> {
    let valid = false;

    if (this.mobileNumberField.length >= 6) {
      try {
        const prepaidInfo = await this.apiService.getPrepaidInfo(this.mobileNumberField);

        if (prepaidInfo && prepaidInfo.brand && prepaidInfo.brand !== PrepaidBrand.UNKNOWN) {
          this.setPrepaidInfo(prepaidInfo);

          valid = true;
        }
      } catch (error) {
        ErrorUtil.registerError(error);
        valid = false;
      }
    }

    this.setIsValidNumber(valid);
    return valid;
  }

  public handleQueryParamMsisdn(): void {
    const msisdn = this.routeStore.queryParam('msisdn');
    if (msisdn) {
      this.setMobileNumber(msisdn);
      this.validateMobileNumber();
    }
  }

  get validMobileNumber(): boolean {
    return this.prepaidInfo != null && this.prepaidInfo.brand != null && this.prepaidInfo.brand != PrepaidBrand.UNKNOWN;
  }

  get detaineeNumber(): boolean {
    return this.validMobileNumber && this.prepaidInfo.brand === PrepaidBrand.PRION;
  }

  private setPrepaidInfo(val: IPrepaidInfo) {
    this.prepaidInfo = val;
  }

  private setIsValidNumber(isValid: boolean) {
    this.validNumber = isValid;
  }

  public mobileNumberFieldErrorHelper(errorLabel: string): any {
    return { text: errorLabel, type: 'error' };
  }

  public mobileNumberFieldNoPlansHelper(errorLabel: string): any {
    return { text: errorLabel, type: 'error' };
  }
}

export default MobileNumberStore;
