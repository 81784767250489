import { observer } from 'mobx-react';
import * as React from 'react';
import { Notice, Separator } from 'telia-front-react';

import { useStores } from '../stores/RootStore';

interface IStatusNoticeProps {
  status?: PaymentStatus;
}

const StatusNotice = observer((props: IStatusNoticeProps) => {
  const { translateStore, posPaymentStore } = useStores();

  const resolveStatus = (): JSX.Element => {
    const translate = translateStore.translate;

    if (translateStore.failedTranslations) {
      return <>Tõlgete saamine ebaõnnestus &#60;Failed label API&#62;</>;
    } else if (props.status === 'success') {
      return <>{translate('prepaid.success')}</>;
    } else if (props.status === 'cancelled') {
      return <>{translate('prepaid.cancelled')}</>;
    } else if (props.status === 'processing') {
      return <>{translate('prepaid.processing')}</>;
    } else if (posPaymentStore.failedPosRequest) {
      return <>{translate('prepaid.pos-payment.failed-to-execute')}</>;
    }
    return null;
  };

  const statusType = (): 'error' | 'success' => {
    const status = props.status;
    if (status === 'success' || status === 'processing') return 'success';
    return 'error';
  };

  return (
    <>
      {resolveStatus() && (
        <>
          <Notice type={statusType()}>{resolveStatus()}</Notice>
          <Separator color="invisible" spacing="xsmall" />
        </>
      )}
    </>
  );
});

export default StatusNotice;
