import * as React from 'react';
import * as ReactDOM from 'react-dom';

import App from './App';

const render = (AppComponent: React.ComponentClass) => {
  ReactDOM.render(<AppComponent />, document.getElementById('container'));
};

render(App);
