const CookieUtil = {
  getCookieValue(name: string): string {
    const pair = document.cookie.match(new RegExp(name + '=([^;]+)'));
    if (pair) {
      return pair[1];
    }
    return null;
  },

  setCookie: (name: string, value: string, expires?: string): void => {
    document.cookie = name + '=' + value + '; expires=' + expires + '; path=/; domain=.telia.ee; secure';
  }
};

export default CookieUtil;
